import Api from '../services';

export default class ProductsBuilder {
    params = {};
    client = (Api.products);

    setSearch(terms) {
        if (typeof terms !== 'string') {
            this.error('setSearch method accept only string');
        }

        this.params.search = terms;
        return this;
    }

    setCategories(categories) {
        if (!Array.isArray(categories)) {
            this.error('setCategories method accept only array');
        }

        this.params.categories = categories;
        return this;
    }

    setCollections(collections) {
        if (!Array.isArray(collections)) {
            this.error('setCollections method accept only array');
        }

        this.params.collections = collections;
        return this;
    }

    setBanners(banners) {
        if (!Array.isArray(banners)) {
            this.error('setBanners method accept only array');
        }

        this.params.banners = banners;
        return this;
    }

    orderByPrice(order = 'asc') {
        if (order !== 'asc' && order !== 'desc') {
            this.error('orderByPrice method accept only asc or desc parameter');
        }

        this.params.order_price = order;
        return this;
    }

    orderBySale(order = 'asc') {
        if (order !== 'asc' && order !== 'desc') {
            this.error('orderBySale method accept only asc or desc parameter');
        }

        this.params.order_sale = order;
        return this;
    }

    orderByLabel(id) {
        if (isNaN(id)) {
            this.error('orderByLabel method accept only integers');
        }

        this.params.order_label_id = id;
        return this;
    }

    orderByNewest(order = 'asc') {
        if (order !== 'asc' && order !== 'desc') {
            this.error('orderByNewest method accept only asc or desc parameter');
        }

        this.params.order_newest = order;
        return this;
    }

    filterWithoutCollectionsItems() {
        this.params.without_collections = 1;
        return this;
    }

    itemsCount(count = 15) {
        if (isNaN(count)) {
            this.error('itemsCount method accept only number value');
        }

        this.params.paginate = count;
        return this;
    }

    page(page) {
        if (isNaN(page)) {
            this.error('page method accept only number value');
        }

        this.params.page = page;
        return this;
    }

    error(msg) {
        throw new Error(`ProductsBuilder: ${msg}`);
    }

    get() {
        return this.client.getProducts(this.params);
    }
}

