import React, {Component, useEffect, useState} from "react";
import withHeaderAndFooter from "../../with-header-and-footer-hoc";
import "./payment-page-body.css";
import PaymentHeader from "../payment-header";
import PaymentForm from "../payment-form";
import {useStore} from "../../../context";
import Api from "../../../services";



const PaymentPageBody = () => {
    const { bag } = useStore();
    const [cartData, setCartData] = useState([]);

    useEffect(()=>{
        const newData = [...bag];
        const requestData = newData.map(item => {
            return {
                stock_id: item.id,
                quantity: item.quantity
            };
        });
        

        Api.cart.getCart({
            items: requestData || []
        }).then(response => {
            const data = response?.data || [];
            setCartData(data);
        })
    }, [bag])

    useEffect(()=>{
      window.scrollTo(0,0)
    }, [])
    return(
        <div className="payment_page_body">
            <PaymentHeader data={cartData} />
            <PaymentForm data={cartData} />
        </div>
    );
}
export default withHeaderAndFooter(PaymentPageBody);