import React, {Component, useState} from "react";
import "./out-of-stock.css";
import TextField from "@material-ui/core/TextField";
import Api from '../../../../../services';


const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
const OutOfStock = ({ productId }) => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

        
    const sendRequestHandler = () => {
    !email.length || !validateEmail(email) ? setEmailError(true) : setEmailError(false);
      

        if (email.length > 0) {
        const form = new FormData();
        form.append('stock_id', productId);
        form.append('email', email);
        Api.products.postSubscribeForProduct(form).then(response => {
            response.success && setEmail('');
        }).catch(error => {
            console.log(error);
        });
    }

};

    return(
        <div className="out_of_stock">
            <div className="out_of_stock_title">
                Don't miss out
            </div>
            <div className="out_of_stock_text">
                Lorem Ipsum is simply dummy text of the
                printing and typesetting industry
            </div>
            <div className="stock_email_container">
                <form  noValidate autoComplete="off">
                    <TextField 
                    error={emailError}
                     label="* Email" value={email} onChange={e => setEmail(e.target.value)}/>
                </form>
            </div>
            <div className="stock_checkbox_container">
                <div className="payment_checkbox_container">
                    <label className="container">
                        <input type="checkbox"/>
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="stock_checkbox_label">
                    Recive Lorem ipsum
                </div>
            </div>
            <div className="stock_button_container">
                <div className="stock_button" onClick={sendRequestHandler}>
                    notify me
                </div>
            </div>
        </div>
    );
}

export default OutOfStock;