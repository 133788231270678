import React,{useEffect, memo, useState} from "react";
import "./details-page-content.css";
import ProductSlider from "./product-slider";
import ProductDescription from "./product-description";
import Api from '../../../../services';

const DetailsPageContent  = props => {
    const {product, sizesHandler} = props;

    return(
        <div className="details_page_content">
            <ProductSlider baseUrl={Api.imgUrl} images={product?.item?.images}/>
            <ProductDescription
                sizesHandler={sizesHandler}
                features={product.features}
                sizes={product.sizes}
                product={product.item}
                shipping={product.shipping_info}
            />
        </div>
    );
}

export default memo(DetailsPageContent);