import config from "../config";
import Axios from 'axios';


class Api {

    headers = () => {
        return {
            'Accept-Language': localStorage.getItem('lang') || 'en',
            'Content-Type':'multipart/form-data'
        }
    }

    getRequest = async (uri, query) => {
        let queryUrl = new URL(`${config.apiBaseUrl}/${uri}`);
        if (query) {
            for (let property in query) {
                if (Array.isArray(query[property])) {
                    const arr = query[property];
                    delete query[property]
                    arr.forEach((element, key) => {
                        if (typeof element === 'object') {
                            for (let property2 in element) {
                                query[`${property}[${key}][${property2}]`] = element[property2]
                            }
                        } else {
                            query[`${property}[${key}]`] = element;
                        }
                    });
                }
            }
            queryUrl.search = new URLSearchParams(query);
        }
        try {
            const res = await fetch(queryUrl, {
                method: 'GET',
                headers: this.headers()
            })
            if (!res.ok) {
                throw new Error(`Could not fetch ${queryUrl}, received ${res.status}`);
            }
            return res.json()
        } catch (error) {
            console.log(error)
        }
    }

    postRequest = async (uri, params) => {
        const url = `${config.apiBaseUrl}/${uri}`
        const res = await Axios({
            method: 'POST',
            url: url,
            headers: this.headers(),
            data: params
        })
        return res.data;
    }
}
export default Api