import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

// const bag = localStorage.getItem('bag');
// const arr = JSON.parse(bag);
const initial = {};

const rootReducer = (state = initial, action) => {
    switch (action.type) {
        case 'COLLECTIONS':
            return { ...state, collections: action.data }
        case 'BANNERS':
            return { ...state, banners: action.data }
        case 'CONTACTS':
            return { ...state, contacts: action.data }
        case 'NAVIGATION':
            return { ...state, navigation: action.data }
        case 'LABELS':
            return { ...state, labels: action.data }
        default:
            return state
    }
}

export const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const ReduxStore = ({ children }) => {

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
}

export default ReduxStore;