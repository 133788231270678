import React,{useEffect, memo, useState} from "react";
import "./collection-header.css";
import { useSelector } from 'react-redux';
import Api from '../../../services';

const CollectionHeader = (props) => {
    const { collectionId } = props;
    const collections = useSelector(store => store.collections);
    const collection = collections?.find(item => Number(item.id) === Number(collectionId))

    return(
        <div className="collection_header" style={{backgroundImage: `url('${Api.imgUrl}/${collection?.banner}')`}}>
            <div className="collection_header_title">
                {collection?.name}
            </div>
        </div>
    );
}

export default memo(CollectionHeader);